import {Permission} from '@hconnect/apiclient'
import {maintainAllApps} from '@hconnect/maintain/src/shared/config'

import {allApps} from './config/'
import {MaintainAppConfig, MaintainAppOption} from './maintain.types'
import plantsRaw from './plants.json'
const plants = plantsRaw as Record<string, PlantJsonItem>
import {PLANT_ID, PLANT_NAME} from './consts'
import {
  AppConfig,
  AppPlusPlant,
  AppPlantSelectOption,
  PlantId,
  PlantJsonItem,
  WhiteList
} from './types'

export type Env = 'prod' | 'qa' | 'uat'

const getPlantIdsForCountry = (countryId: string) =>
  Object.entries(plants)
    .filter(([, details]) => details.countryId === countryId)
    .map(([plantId]) => plantId)

const checkIsPlantNotWhitelisted = (appConfig: AppConfig, plantId: PlantId) => {
  if (!appConfig.plant?.whiteList) {
    return false
  }
  return !appConfig.plant.whiteList.includes(plantId)
}
const checkIsPlantBlacklisted = (appConfig: AppConfig, plantId: PlantId) => {
  if (!appConfig.plant?.blackList) {
    return false
  }
  return appConfig.plant?.blackList.includes(plantId)
}

const parseDataScope = (dataScope: string) => {
  try {
    const parsedScope = JSON.parse(dataScope) as Partial<{plantId: string; countryId: string}>
    return parsedScope
  } catch (error) {
    throw new Error('BUG: Error during parsing permission dataScope')
  }
}

export const addToWhiteList = (
  env: Env,
  whiteList: WhiteList,
  appConfig: AppConfig,
  plantId: PlantId
) => {
  const entry: PlantJsonItem | undefined = plants[plantId]
  const plantName = entry?.name || plantId
  const appName = appConfig.name

  const url = appConfig.url[env].replace(PLANT_ID, plantId).replace(PLANT_NAME, plantName)

  const option: AppPlantSelectOption = {
    appName,
    plantId,
    plantName,
    url
  }

  if (!whiteList.optionsPerApp[appName]) {
    whiteList.optionsPerApp[appName] = [option]
  } else if (
    !whiteList.optionsPerApp[appName]?.find(
      (current: AppPlantSelectOption) => current.plantId === plantId
    )
  ) {
    whiteList.optionsPerApp[appName]?.push(option)
  }

  if (!whiteList.optionsPerPlant[plantId]) {
    whiteList.optionsPerPlant[plantId] = [option]
  } else if (
    !whiteList.optionsPerPlant[plantId].find(
      (current: AppPlantSelectOption) => current.appName === appName
    )
  ) {
    whiteList.optionsPerPlant[plantId].push(option)
  }

  whiteList.optionsMap.set(appName + plantId, option)
}

export const addToMaintainWhiteList = (
  env: Env,
  whiteList: WhiteList,
  appConfig: MaintainAppConfig,
  plantId: PlantId
) => {
  const entry: PlantJsonItem | undefined = plants[plantId]
  const plantName = entry?.name || plantId
  const appName = appConfig.name

  const option: MaintainAppOption = {
    appName,
    plantId,
    plantName
  }

  if (!whiteList.maintainApps[appName]) {
    whiteList.maintainApps[appName] = [option]
  } else if (
    !whiteList.maintainApps[appName]?.find(
      (current: MaintainAppOption) => current.plantId === plantId
    )
  ) {
    whiteList.maintainApps[appName]?.push(option)
  }

  if (!whiteList.maintainApps[plantId]) {
    whiteList.maintainApps[plantId] = [option]
  } else if (
    !whiteList.maintainApps[plantId].find(
      (current: AppPlantSelectOption) => current.appName === appName
    )
  ) {
    whiteList.maintainApps[plantId].push(option)
  }
}

/**
 * Gives a list of all the app + plant combinations that a user has access to
 */
export const whiteListByPermission = (
  env: Env = 'prod',
  usersPermissions: Permission[]
): WhiteList => {
  const whiteList: WhiteList = {
    optionsPerApp: {},
    optionsPerPlant: {},
    optionsMap: new Map<AppPlusPlant, AppPlantSelectOption>(),
    missedPlantIds: [],
    maintainApps: {}
  }

  // to maintain the same order of the apps, we iterate over 'allApps' first and then usersPermissions
  allApps.forEach((appConfig) => {
    usersPermissions.forEach((usersPermission) => {
      const isUserPermissionInAppPermission = appConfig.whiteListedPermissionTypes.includes(
        usersPermission.permissionType
      )
      if (!isUserPermissionInAppPermission) {
        return
      }

      // parsing permission dataScope
      const parsedDataScope = parseDataScope(usersPermission.dataScope)

      // const isPermissionGrantedByDataScope = checkPermissionByDataScope()
      const plantId = parsedDataScope.plantId
      const countryId = parsedDataScope.countryId

      if (!countryId) {
        return
      }

      // if it's a specific plant permission
      if (plantId) {
        // This block of code is for tracking if we do not have a plant configured in the plant configuration json.  If there are no missed plants then there is nothing to change.
        if (!plants[plantId]) {
          if (!whiteList.missedPlantIds) {
            whiteList.missedPlantIds = [plantId]
          } else if (!whiteList.missedPlantIds.includes(plantId)) {
            whiteList.missedPlantIds.push(plantId)
          }
        }

        if (checkIsPlantNotWhitelisted(appConfig, plantId)) {
          return
        }
        if (checkIsPlantBlacklisted(appConfig, plantId)) {
          return
        }
        return addToWhiteList(env, whiteList, appConfig, plantId)
      }
      // it's country level permission we need to add all related plantIds from plants to whitelist
      const plantIdsForCountry = getPlantIdsForCountry(countryId)
        .filter((plantId) => !checkIsPlantNotWhitelisted(appConfig, plantId))
        .filter((plantId) => !checkIsPlantBlacklisted(appConfig, plantId))

      plantIdsForCountry.forEach((plantId) => addToWhiteList(env, whiteList, appConfig, plantId))
    })
  })

  maintainAllApps.forEach((appConfig) => {
    usersPermissions.forEach((usersPermission) => {
      // Add Maintain sub applications to global context
      const isUserPermissionInAppPermission = appConfig.whiteListedPermissionTypes.includes(
        usersPermission.permissionType
      )
      if (!isUserPermissionInAppPermission) {
        return
      }

      // parsing permission dataScope
      const parsedDataScope = parseDataScope(usersPermission.dataScope)

      // const isPermissionGrantedByDataScope = checkPermissionByDataScope()
      const plantId = parsedDataScope.plantId
      const countryId = parsedDataScope.countryId

      if (!countryId) {
        return
      }

      // if it's a specific plant permission
      if (plantId) {
        return addToMaintainWhiteList(env, whiteList, appConfig, plantId)
      }
      // it's country level permission we need to add all related plantIds from plants to whitelist
      const plantIdsForCountry = getPlantIdsForCountry(countryId)
      plantIdsForCountry.forEach((plantId) =>
        addToMaintainWhiteList(env, whiteList, appConfig, plantId)
      )
    })
  })

  return whiteList
}

export const getPlantName = (plantId: PlantId) =>
  (plantsRaw[plantId] as PlantJsonItem)?.name || plantId
